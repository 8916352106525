<template>
  <!doctype html>
  <!-- Pintex - Mobile App & Software Landing Page Template design by Jthemes (https://www.jthemes.com) -->
  <!--[if lt IE 7 ]><html class="ie ie6" lang="en"> <![endif]-->
  <!--[if IE 7 ]><html class="ie ie7" lang="en"> <![endif]-->
  <!--[if IE 8 ]><html class="ie ie8" lang="en"> <![endif]-->
  <!--[if (gte IE 9)|!(IE)]><!-->
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="author" content="Jthemes">
    <meta name="description" content="Pintex - Mobile App & Software Landing Page Template">
    <meta name="keywords" content="Responsive, HTML5 template, Jthemes, Mobile, Application, Software, One Page, Landing, Digital Product, Mobile App">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <!-- SITE TITLE -->
    <title>Pintex - Mobile App & Software Landing Page Template</title>
    <!-- FAVICON AND TOUCH ICONS -->
    <!-- GOOGLE FONTS -->


    <!-- BOOTSTRAP CSS -->

    <!-- FONT ICONS -->

    <!-- PLUGINS STYLESHEET -->






    <!-- ON SCROLL ANIMATION -->

    <!-- TEMPLATE CSS -->







    <!-- RESPONSIVE CSS -->

  </head>
  <body>




  <!-- PRELOADER SPINNER
  ============================================= -->
  <div id="loading">
    <div id="loader" class="loading--theme">
      <div class="cssload-spinner"></div>
    </div>
  </div>




  <!-- PAGE CONTENT
  ============================================= -->
  <div id="page" class="page">




    <!-- HEADER
    ============================================= -->
    <header id="header" class="tra-menu navbar-dark white-scroll">
      <div class="header-wrapper">


        <!-- MOBILE HEADER -->
        <div class="wsmobileheader clearfix">
				    	<span class="smllogo">



				    	</span>
          <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
        </div>


        <!-- NAVIGATION MENU -->
        <div class="wsmainfull menu clearfix">
          <div class="wsmainwp clearfix">


            <!-- HEADER BLACK LOGO -->
            <div class="desktoplogo">


            </div>


            <!-- HEADER WHITE LOGO -->
            <div class="desktoplogo">

            </div>


            <!-- MAIN MENU -->
            <nav class="wsmenu clearfix">
              <ul class="wsmenu-list nav-theme">


                <!-- DROPDOWN SUB MENU -->
                <li aria-haspopup="true">

                  <ul class="sub-menu ico-10">




                  </ul>
                </li>


                <!-- SIMPLE NAVIGATION LINK -->
                <li class="nl-simple" aria-haspopup="true">

                </li>


                <!-- SIMPLE NAVIGATION LINK -->
                <li class="nl-simple" aria-haspopup="true">

                </li>


                <!-- DROPDOWN SUB MENU -->
                <li aria-haspopup="true">

                  <ul class="sub-menu ico-10">



                  </ul>
                </li>


                <!-- SIMPLE NAVIGATION LINK -->
                <li class="nl-simple" aria-haspopup="true">

                </li>


                <!-- SIGN IN LINK -->
                <li class="nl-simple reg-fst-link" aria-haspopup="true">

                </li>


                <!-- SIGN UP BUTTON -->
                <li class="nl-simple mobile-last-link" aria-haspopup="true">

                </li>

              </ul>
            </nav>	<!-- END MAIN MENU -->


          </div>
        </div>	<!-- END NAVIGATION MENU -->


      </div>     <!-- End header-wrapper -->
    </header>	<!-- END HEADER -->




    <!-- HERO
    ============================================= -->
    <section id="hero-4" class="bg--fixed hero-section">
      <div class="container text-center">


        <!-- HERO TEXT -->
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-9">
            <div class="hero-4-txt wow animate__animated animate__fadeInUp">

              <!-- Title -->
              <h2>Система аналитики продаж на Wildberries</h2>

              <!-- Text -->
              <p class="p-xl">Точные данные продаж по регионам и складам, с учетом всех расходов и комиссий - для точного планирования
              </p>

              <!-- Button -->


            </div>
          </div>
        </div>	<!-- END HERO TEXT -->


        <!-- HERO IMAGE -->
        <div class="row">
          <div class="col">
            <div class="hero-4-img wow animate__animated animate__fadeInUp">

            </div>
          </div>
        </div>	<!-- END HERO IMAGE -->


      </div>    <!-- End container -->
    </section>	<!-- END HERO -->





    <!-- DIVIDER LINE -->
    <hr class="divider">




    <!-- TEXT CONTENT
    ============================================= -->
    <section id="benefits" class="pt-100 ct-01 content-section division">
      <div class="container">
        <div class="row d-flex align-items-center">


          <!-- TEXT BLOCK -->
          <div class="col-md-6 order-last order-md-2">
            <div class="txt-block left-column wow animate__animated animate__fadeInRight">

              <!-- Title -->
              <h2 class="h2-md"> Реализовал свои наработки</h2>

              <!-- Text -->
              <p>Когда маркетплейсы начали стремительно расти, я заметил, что на рынке не хватает удобных инструментов для анализа продаж.
                Тогда я вручную делал расчеты в Google Таблицах, что стало основой для моего IT проекта.
              </p>

              <!-- Text -->
              <ul class="simple-list">

                <li class="list-item">
                  <p>Я не программист, и реализация проекта была настоящим вызовом. Но я стремился к высокой точности данных,
                    чтобы селеры могли видеть реальные доходы и расходы — то, что важно для успеха каждого бизнеса.
                  </p>
                </li>



              </ul>

            </div>
          </div>	<!-- END TEXT BLOCK -->


          <!-- IMAGE BLOCK -->
          <div class="col-md-6 order-first order-md-2">
            <div class="img-block right-column wow animate__animated animate__fadeInLeft">

            </div>
          </div>


        </div>    <!-- End row -->
      </div>	   <!-- End container -->
    </section>	<!-- END TEXT CONTENT -->


    <!-- DIVIDER LINE -->
    <hr class="divider">


    <!-- FEATURES
    ============================================= -->
    <section id="features" class="py-100 features-2 features-section division">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-title text-center mb-80">

              <!-- Title -->
              <h2 class="h2-xl">Все в одном месте</h2>

              <!-- Text -->
              <p class="p-lg">Только нужные инструменты для каждого товара</p>

            </div>
          </div>
        </div>


        <!-- FEATURES WRAPPER -->
        <div class="fbox-wrapper">
          <div class="row row-cols-1 row-cols-md-2 rows-3">


            <!-- FEATURE BOX #1 -->
            <div class="col">
              <div class="fbox-2 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-browser-2"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Полная аналитика</h5>
                  <p>Инструмент для детального анализа продаж: количество заказов, выкуп, себестоимость, продвижение, остатки и движение товаров.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #1 -->


            <!-- FEATURE BOX #2 -->
            <div class="col">
              <div class="fbox-2 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-color-palette"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Динамика продаж</h5>
                  <p>Графики заказов и продаж по дням помогают отслеживать тренды спроса и находить причины их изменений.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #2 -->


            <!-- FEATURE BOX #3 -->
            <div class="col">
              <div class="fbox-2 fb-3 wow animate__animated animate__fadeInUp animate__delay-1">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-ai"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Прогноз запасов</h5>
                  <p>Автоматический расчет необходимого пополнения товара с возможностью мгновенного создания документа для поставщика.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #3 -->


            <!-- FEATURE BOX #4 -->
            <div class="col">
              <div class="fbox-2 fb-4 wow animate__animated animate__fadeInUp animate__delay-2">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-folder-1"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Калькулятор прибыли</h5>
                  <p>Планируйте маржинальность еще до вывода товара на маркетплейс, учитывая цену, скидки и комиссии.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #4 -->


            <!-- FEATURE BOX #5 -->
            <div class="col">
              <div class="fbox-2 fb-5 wow animate__animated animate__fadeInUp animate__delay-1">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-alter"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Реальные расходы</h5>
                  <p>Отдельный блок для учета комиссий, логистики, штрафов и прочих удержаний с полной прозрачностью.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #5 -->


            <!-- FEATURE BOX #6 -->
            <div class="col">
              <div class="fbox-2 fb-6 wow animate__animated animate__fadeInUp animate__delay-2">

                <!-- Icon -->
                <div class="fbox-ico-wrap ico-55">
                  <div class="shape-ico color--theme-2">

                    <!-- Vector Icon -->
                    <span class="flaticon-union"></span>

                    <!-- Shape -->
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                      <path d="M66.1,-32.6C77.9,-17.7,74.4,11.6,60.8,35.7C47.2,59.7,23.6,78.5,2.8,76.9C-18,75.2,-35.9,53.2,-47.7,30.1C-59.5,7.1,-65.1,-16.9,-56.1,-30.1C-47.1,-43.4,-23.6,-46,1.8,-47C27.2,-48.1,54.3,-47.6,66.1,-32.6Z" transform="translate(100 100)" />
                    </svg>

                  </div>
                </div>	<!-- End Icon -->

                <!-- Text -->
                <div class="fbox-txt">
                  <h5>Учет заказов</h5>
                  <p>Мониторинг заказов в реальном времени: статус, регион, цена, скидки и путь товара вплоть до клиента.
                  </p>
                </div>

              </div>
            </div>	<!-- END FEATURE BOX #6 -->


          </div>  <!-- End row -->
        </div>	<!-- END FEATURES WRAPPER -->


      </div>     <!-- End container -->
    </section>	<!-- END FEATURES -->




    <!-- DIVIDER LINE -->
    <hr class="divider">




    <!-- IMAGE CONTENT
    ============================================= -->
    <section id="how-it-works" class="ct-06 content-section division">
      <div class="section-overlay bg--03 pt-100">
        <div class="container">


          <!-- SECTION TITLE -->
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8">
              <div class="section-title text-center mb-60">

                <!-- Title -->
                <h2 class="h2-xl">Краткий видеообзор</h2>

                <!-- Text -->
                <p class="p-lg">Как быстро посчитать куда уходит прибыль с продажи товаров</p>

              </div>
            </div>
          </div>


          <!-- IMAGE BLOCK -->
          <div class="row">
            <div class="col">
              <div class="img-block video-preview wow animate__animated animate__fadeInUp">

                <!-- Play Icon -->

                  <div class="video-btn video-btn-xl bg--theme-2">
                    <div class="video-block-wrapper"><span class="flaticon-play-button"></span></div>
                  </div>
                <!-- Preview Image -->


              </div>
            </div>
          </div>


        </div>	   <!-- End container -->
      </div>     <!-- End section overlay -->
    </section>	<!-- END IMAGE CONTENT -->




    <!-- STATISTIC
    ============================================= -->
    <div class="py-100 statistic-1 statistic-section division">
      <div class="container">


        <!-- STATISTIC WRAPPER -->
        <div class="statistic-1-wrapper">
          <div class="row justify-content-md-center row-cols-1 row-cols-md-3">


            <!-- STATISTIC BLOCK #1 -->
            <div class="col">
              <div id="sb-1-1" class="animate__animated animate__fadeInUp">
                <div class="statistic-block">

                  <!-- Digit -->
                  <div class="statistic-block-digit text-center">
                    <h2 class="h2-xl statistic-number">
                      <span class="count-element">378</span><small>K</small>
                    </h2>
                  </div>

                  <!-- Text -->
                  <div class="statistic-block-txt color--gray">
                    <p class="p-md">Селлеров не ведут учета</p>
                  </div>

                </div>
              </div>
            </div>	<!-- END STATISTIC BLOCK #1 -->


            <!-- STATISTIC BLOCK #2 -->
            <div class="col">
              <div id="sb-1-2" class="animate__animated animate__fadeInUp">
                <div class="statistic-block">

                  <!-- Digit -->
                  <div class="statistic-block-digit text-center">
                    <h2 class="h2-xl statistic-number">
                      <span class="count-element">16</span><small>%</small>
                    </h2>
                  </div>

                  <!-- Text -->
                  <div class="statistic-block-txt color--gray">
                    <p class="p-md">Теряется без учета расходов</p>
                  </div>

                </div>
              </div>
            </div>	<!-- END STATISTIC BLOCK #2 -->


            <!-- STATISTIC BLOCK #3 -->
            <div class="col">
              <div id="sb-1-3" class="animate__animated animate__fadeInUp">
                <div class="statistic-block">

                  <!-- Digit -->
                  <div class="statistic-block-digit text-center">
                    <h2 class="h2-xl statistic-number">
                      <span class="count-element">4</span>,<span class="count-element">3</span>
                    </h2>
                  </div>

                  <!-- Text -->
                  <div class="statistic-block-txt color--gray">
                    <p class="p-md">Увеличивается прибыль за счет оптимизации</p>
                  </div>

                </div>
              </div>
            </div>	<!-- END STATISTIC BLOCK #3 -->


          </div>  <!-- End row -->
        </div>	<!-- END STATISTIC WRAPPER -->


      </div>	 <!-- End container -->
    </div>	 <!-- END STATISTIC -->




    <!-- DIVIDER LINE -->
    <hr class="divider">






    <!-- DIVIDER LINE -->
    <hr class="divider">




    <!-- PRICING-3
    ============================================= -->
    <section id="pricing" class="pt-100 pricing-3 pricing-section division">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-title mb-60 text-center">

              <!-- Title -->
              <h2 class="h2-xl">Тарифы</h2>

              <!-- Text -->
              <p class="p-lg">Смотрите детальный разбор тут</p>

            </div>
          </div>
        </div>	<!-- END SECTION TITLE -->


        <!-- PRICING TABLES -->
        <div class="pricing-3-wrapper">
          <div class="row d-flex align-items-end justify-content-center">


            <!-- FREE PLAN -->
            <div class="col-md-6 col-lg-5">
              <div id="pt-3-1" class="p-table pricing-3-table bg--white r-24 wow animate__animated animate__fadeInRight">


                <!-- TABLE HEADER -->
                <div class="pricing-table-header text-center">

                  <!-- Title -->
                  <h5 class="h5-md color--purple">Базовый</h5>

                  <!-- Price -->
                  <div class="price">
                    <sup>₽</sup>
                    <span>0</span>
                    <sup class="validity">&nbsp;/&ensp;мес</sup>
                  </div>

                </div>	<!-- END TABLE HEADER -->

                <!-- PRICING FEATURES -->
                <ul class="pricing-features ico-10 ico--theme-2">
                  <li><p><span class="flaticon-check"></span> Данные по заказам</p></li>
                  <li><p><span class="flaticon-check"></span> Unit калькулятор</p></li>
                  <li><p><span class="flaticon-check"></span> Планирование закупок</p></li>
                </ul>

              </div>
            </div>	<!-- END FREE PLAN -->


            <!-- PREMIUM PLAN -->
            <div class="col-md-6 col-lg-5">


              <!-- PRICING TABLE -->
              <div id="pt-3-2" class="p-table pricing-3-table bg--white r-24 wow animate__animated animate__fadeInLeft">


                <!-- TABLE HEADER -->
                <div class="pricing-table-header text-center">

                  <!-- Title -->
                  <h5 class="h5-md color--purple">Premium</h5>

                  <!-- Price -->
                  <div class="price">
                    <sup>₽</sup>
                    <span>2 990</span>
                    <sup class="validity">&nbsp;/&ensp;мес</sup>
                  </div>

                </div>	<!-- END TABLE HEADER -->

                <!-- PRICING FEATURES -->
                <ul class="pricing-features ico-10 ico--theme-2">
                  <li><p><span class="flaticon-check"></span> Детальная аналитика</p></li>
                  <li><p><span class="flaticon-check"></span> Подсортировка товара</p></li>
                  <li><p><span class="flaticon-check"></span> Учет продаж по регионам</p></li>
                  <li><p><span class="flaticon-check"></span> Учет продвижения</p></li>
                </ul>

              </div>	<!-- END PRICING TABLE -->


            </div>	<!-- END PREMIUM PLAN  -->


          </div>
        </div>	<!-- PRICING TABLES -->


        <!-- PRICING NOTICE TEXT -->
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="pricing-notice wow animate__animated animate__fadeInUp">

              <!-- Button -->


            </div>
          </div>
        </div>	<!-- END PRICING NOTICE TEXT -->


      </div>	   <!-- End container -->
    </section>	<!-- END PRICING-3 -->








    <!-- FAQs
    ============================================= -->
    <section id="faqs" class="py-100 faqs-1 faqs-section division">
      <div class="container">


        <!-- SECTION TITLE -->
        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-8">
            <div class="section-title text-center mb-60">

              <!-- Title -->
              <h2 class="h2-xl">Questions? Look Here.</h2>

              <!-- Text -->
              <p class="p-lg">Some questions about Pintex are asked frequently. We've answered the most
                frequent of those frequent questions below
              </p>

            </div>
          </div>
        </div>


        <!-- FAQs QUESTIONS -->
        <div class="faqs-1-questions">
          <div class="row justify-content-center">


            <!-- QUESTIONS ACCORDION -->
            <div class="col-xl-11">
              <div class="accordion-wrapper">
                <ul class="accordion">


                  <!-- QUESTION #1 -->
                  <li class="accordion-item is-active wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>Get started with Pintex</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <p>Sagittis congue augue egestas volutpat ipsum egestas suscipit egestas magna ipsum cursus purus congue efficitur and ipsum primis dolor cubilia laoreet augue an egestas luctus donec dapibus and curabitur
                      </p>

                    </div>

                  </li>	<!-- END QUESTION #1 -->


                  <!-- QUESTION #2 -->
                  <li class="accordion-item wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>What devices are compatible with Pintex?</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <p>Sagittis congue augue egestas volutpat ipsum egestas suscipit egestas magna
                        ipsum cursus purus congue efficitur and ipsum primis dolor cubilia laoreet augue an egestas luctus donec dapibus and curabitur
                      </p>

                      <!-- Text -->
                      <p>Sapien egestas, congue gestas diam posuere cubilia congue in ipsum mauris lectus
                        laoreet gestas undo neque vitae ipsum auctor dolor luctus placerat a magna cursus congue nihil magna mpedit ligula congue and maecenas gravida porttitor quis congue vehicula magna luctus tempor quisque laoreet turpis. Viverra augue augue eget dictum tempor diam. Sed pulvinar consectetur  nibh, imperdiet varius viverra
                        cursus purus congue efficitur
                      </p>

                    </div>

                  </li>	<!-- END QUESTION #2 -->


                  <!-- QUESTION #3 -->
                  <li class="accordion-item wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>Download the Pintex app</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus and felis
                        primis augue ultrice ligula turpis egestas a suscipit lectus gestas integer congue a lectus porta neque phasellus blandit tristique
                      </p>

                      <!-- Text -->
                      <p>Sagittis congue augue egestas volutpat diam egestas a magna suscipit egestas and
                        magna ipsum vitae
                      </p>

                      <!-- Text -->
                      <p>Sagittis congue augue and egestas volutpat egestas and magna suscipit egestas
                        magna ipsum vitae purus congue and efficitur ipsum primis in cubilia laoreet augue
                        egestas luctus donec and curabitur dapibus
                      </p>

                    </div>

                  </li>	<!-- END QUESTION #3 -->


                  <!-- QUESTION #4 -->
                  <li class="accordion-item wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>Update to the latest version of Pintex</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <p>Sapien egestas, congue gestas diam posuere cubilia congue in ipsum mauris lectus
                        laoreet gestas undo neque vitae ipsum auctor dolor luctus placerat a magna cursus congue nihil magna mpedit ligula congue and maecenas gravida porttitor quis congue vehicula magna luctus tempor quisque laoreet turpis. Viverra augue augue eget dictum tempor diam. Sed pulvinar consectetur  nibh, imperdiet varius viverra
                        cursus purus congue efficitur
                      </p>

                      <!-- Text -->
                      <p>Sagittis congue augue egestas volutpat ipsum egestas suscipit egestas magna
                        ipsum cursus purus congue efficitur and ipsum primis dolor cubilia laoreet augue an egestas luctus donec dapibus and curabitur
                      </p>

                    </div>

                  </li>	<!-- END QUESTION #4 -->


                  <!-- QUESTION #5 -->
                  <li class="accordion-item wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>How do I choose a plan?</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <ul class="simple-list">

                        <li class="list-item">
                          <p>Curabitur dapibus libero quisque eu congue tristique neque. Phasellus
                            blandit tristique in justo lectus aliquam. Aliquam vitae molestie nunc. Quisque sapien justo, aliquet molestie sed purus, venenatis tempor gravida lacinia. Augue aliquam a suscipit tincidunt tincidunt massa
                            porttitor ipsum
                          </p>
                        </li>

                        <li class="list-item">
                          <p>Aliquam vitae molestie nunc quisque sapien justo, aliquet non molestie
                            purus, venenatis
                          </p>
                        </li>

                        <li class="list-item">
                          <p>Sagittis congue augue and egestas volutpat egestas magna suscipit an
                            egestas magna ipsum vitae purus efficitur ipsum primis in cubilia laoreet
                            augue egestas luctus donec curabitur dapibus libero
                          </p>
                        </li>

                      </ul>

                    </div>

                  </li>	<!-- END QUESTION #5 -->


                  <!-- QUESTION #6 -->
                  <li class="accordion-item acc-last-item wow animate__animated animate__fadeInUp">

                    <!-- Question -->
                    <div class="accordion-thumb">
                      <h5>What is the refund policy?</h5>
                    </div>

                    <!-- Answer -->
                    <div class="accordion-panel">

                      <!-- Text -->
                      <p>An augue cubilia laoreet and magna suscipit egestas magna ipsum purus and felis
                        primis augue ultrice ligula turpis egestas a suscipit lectus gestas integer congue a lectus porta neque phasellus blandit tristique
                      </p>

                      <!-- Text -->
                      <p>Sagittis congue augue egestas volutpat diam egestas a magna suscipit egestas and
                        magna ipsum vitae
                      </p>

                      <!-- Text -->
                      <p>Sagittis congue augue and egestas volutpat egestas and magna suscipit egestas
                        magna ipsum vitae purus congue and efficitur ipsum primis in cubilia laoreet augue
                        egestas luctus donec and curabitur dapibus
                      </p>

                    </div>

                  </li>	<!-- END QUESTION #6 -->


                </ul>
              </div>
            </div>	<!-- END QUESTIONS ACCORDION -->


            <!-- MORE QUESTIONS -->
            <div class="more-questions clearfix">
              <div class="more-questions-holder wow animate__animated animate__fadeInUp">

                <div class="more-questions-txt">
                  <h6 class="h6-xl">Can’t find the answer to your question?</h6>
                  <p class="p-sm">Contact us and we’ll get back to you as soon as we can.</p>
                </div>

                <div class="more-questions-btn">

                </div>

              </div>
            </div>


          </div>  <!-- End row -->
        </div>	<!-- END FAQs QUESTIONS -->


      </div>	   <!-- End container  -->
    </section>	<!-- END FAQs -->




    <!-- BANNER
    ============================================= -->
    <section id="download" class="banner-2 banner-section">
      <div class="container">


        <!-- BANNER WRAPPER -->
        <div class="banner-2-wrapper r-24">
          <div class="banner-overlay bg--04 bg--fixed">
            <div class="row justify-content-center">


              <!-- BANNER TEXT -->
              <div class="col-md-9 col-lg-8">
                <div class="banner-2-txt color--white">

                  <!-- Title -->
                  <h2 class="h2-xl">Начни считать прибыль уже сейчас!</h2>

                  <!-- Text -->
                  <p class="p-lg">Достаточно указать свой APi ключ и и вы получите все данные по доходам и расходам - что позволит понять сколько вы зарабатываете!
                  </p>

                  <!-- Button -->


                </div>
              </div>	<!-- END BANNER TEXT -->




            </div>   <!-- End row -->
          </div>   <!-- End banner overlay -->
        </div>    <!-- END BANNER WRAPPER -->


      </div>     <!-- End container -->
    </section>	<!-- END BANNER -->




    <!-- FOOTER
    ============================================= -->
    <footer class="pt-100 footer-1 footer ft-2-rows division">
      <div class="container">


        <!-- FOOTER CONTENT -->
        <div class="row footer-links clearfix">


          <!-- FOOTER LINKS #1 -->
          <div class="col-md-2">
            <div class="fl-1">

              <!-- Title -->
              <h6 class="d-title">Pintex</h6>
              <h6 class="m-title">Pintex</h6>

              <!-- Links -->
              <ul class="foo-links clearfix">






              </ul>

            </div>
          </div>	<!-- END FOOTER LINKS #1 -->


          <!-- FOOTER LINKS #2 -->
          <div class="col-md-4">
            <div class="fl-2">

              <!-- Title -->
              <h6 class="d-title">Features</h6>
              <h6 class="m-title">Features</h6>

              <!-- Links -->
              <ul class="foo-links clearfix">






              </ul>

            </div>
          </div>	<!-- END FOOTER LINKS #2 -->


          <!-- FOOTER LINKS #3,4 -->
          <div class="col-md-3">


            <!-- FOOTER LINKS #3 -->
            <div class="fl-3">

              <!-- Title -->
              <h6 class="d-title">Discover</h6>
              <h6 class="m-title">Discover</h6>

              <!-- Links -->
              <ul class="foo-links clearfix">


              </ul>

            </div>	<!-- END FOOTER LINKS #3 -->


            <!-- FOOTER LINKS #4 -->
            <div class="fl-4">

              <!-- Title -->
              <h6 class="d-title">Legal</h6>
              <h6 class="m-title">Legal</h6>

              <!-- Links -->
              <ul class="foo-links clearfix">



              </ul>

            </div>	<!-- END FOOTER LINKS #4 -->


          </div>	<!-- END FOOTER LINKS #3,4 -->


          <!-- FOOTER LINKS #5,6 -->
          <div class="col-md-3">


            <!-- FOOTER LINKS #5 -->
            <div class="fl-5">

              <!-- Title -->
              <h6 class="d-title">Company</h6>
              <h6 class="m-title">Company</h6>

              <!-- Links -->
              <ul class="foo-links clearfix">



              </ul>

            </div>	<!-- END FOOTER LINKS #5 -->


            <!-- FOOTER NEWSLETTER FORM -->
            <div class="footer-form">

              <!-- Title -->
              <h6>Follow the Best</h6>

              <!-- Newsletter Form Input -->
              <form class="newsletter-form">

                <div class="input-group r-06">
                  <input type="email" class="form-control" placeholder="Email Address" required id="s-email">
                  <span class="input-group-btn ico-15">
											<button type="submit" class="btn">
												<span class="flaticon-right-arrow"></span>
											</button>
										</span>
                </div>

                <!-- Newsletter Form Notification -->
                <label for="s-email" class="form-notification"></label>

              </form>

            </div>	<!-- END FOOTER NEWSLETTER FORM -->


          </div>	<!-- END FOOTER LINKS #5,6 -->


        </div>	<!-- END FOOTER CONTENT -->


        <hr>	<!-- FOOTER DIVIDER LINE -->


        <!-- BOTTOM FOOTER -->
        <div class="bottom-footer">
          <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">


            <!-- FOOTER COPYRIGHT -->
            <div class="col">
              <div class="footer-copyright copyright-logo">
                <div class="footer-copyright-logo">

                </div>
                <p class="p-sm">&copy; 2024 Marketmetric. <span>Все права защищены</span></p>
              </div>
            </div>



          </div>  <!-- End row -->
        </div>	<!-- END BOTTOM FOOTER -->


      </div>     <!-- End container -->
    </footer>   <!-- END FOOTER -->




  </div>	<!-- END PAGE CONTENT -->




  <!-- EXTERNAL SCRIPTS
  ============================================= -->

















  <!-- Custom Script -->


  <!-- COOKIES MESSAGE -->
  <script>
    $(document).ready(function () {
      setTimeout(function () {
        $.CookiesMessage();
      }, 1800)
    });
  </script>



  <script>
    $(document).on({
      "contextmenu": function (e) {
        console.log("ctx menu button:", e.which);

        // Stop the context menu
        e.preventDefault();
      },
      "mousedown": function(e) {
        console.log("normal mouse down:", e.which);
      },
      "mouseup": function(e) {
        console.log("normal mouse up:", e.which);
      }
    });
  </script>


  </body>
  </html>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'MainPage',
  components: {},
  data() {
    return {
    };
  },

  watch: {

  },

  methods: {

  }
};
</script>

<style scoped>

</style>

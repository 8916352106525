const resolveTypDelivery = {
    fbo: 'kgvp_marketplace',
    fbs: 'kgvp_supplier',
    dbs: 'paid_storage_kgvp',
    express: 'kgvp_supplier_express',
};

let assignValue = {
    sales_plan({row, val}) {
        row.sales_plan = val
    },
    discount_spp({row, val}) {
        row.discount_spp = val
    },
    new_price({row, val}) {
        row.new_price = val;
    },

    purchase_price_total({row, val}) {
        row.purchase_price_total = val
    },
    purchase_price_unit({row, val}) {
        row.purchase_price_unit = val
    },

    delivery_calc_total({row, val}) {
        row.delivery_calc_total = val
    },
    delivery_calc_unit({row, val}) {
        row.delivery_calc_unit = val
    },

    prepend_total({row, val}) {
        row.prepend_total = val
        if (row.amount_sales) {
            row.prepend_unit = Math.round(+val / row.amount_sales)
        }
    },
    prepend_unit({row, val}) {
        row.prepend_unit = val
        row.prepend_total = Math.round(+val * row.amount_sales)
    },

    variant_delivery({row, val}) {
        row.variant_delivery = val
    },
    warehouse({row, val}) {
        row.warehouse = val.warehouse_name
        row.box_delivery_and_storage_expr = val.box_delivery_and_storage_expr;
        row.box_delivery_base = val.box_delivery_base;
        row.box_delivery_liter = val.box_delivery_liter;
        row.box_storage_base = val.box_storage_base;
        row.box_storage_liter = val.box_storage_liter;
    },

    product_turnover({row, val}) {
        row.product_turnover = val;
    },
    promo_percent({row, val}) {
        row.promo_percent = val
    },
}

let calcItems = {
    amount_sales({row}) {
        if (row.new_price || +row.discounted_price) {
            row.amount_sales = Math.round(+row.sales_plan / (row.new_price || +row.discounted_price));
        }
        console.log('row.amount_sales', row.amount_sales)
    },
    final_price({row}) {
        row.final_price = Math.round(row.new_price || +row.discounted_price - ((row.new_price || +row.discounted_price / 100) * row.discount_spp));
    },

    purchase_price_total({row}) {
        row.purchase_price_total = Math.round(row.purchase_price_unit * row.amount_sales)
    },
    purchase_price_unit({row}) {
        if (row.amount_sales) {
            row.purchase_price_unit = Math.round(row.purchase_price_total / row.amount_sales)
        }
    },

    delivery_calc_total({row}) {
        row.delivery_calc_total = Math.round(row.delivery_calc_unit * row.amount_sales)
    },
    delivery_calc_unit({row}) {
        if (row.amount_sales) {
            row.delivery_calc_total = Math.round(+row.delivery_calc_total / row.amount_sales)
        }
    },

    prepend_total({row}) {
        row.prepend_total = Math.round(+row.prepend_unit * row.amount_sales)
    },
    prepend_unit({row}) {
        if (row.amount_sales) {
            row.prepend_unit = Math.round(+row.prepend_total / row.amount_sales)
        }
    },

    cost_price({row}) {
        row.cost_price = Math.round((+row.purchase_price_total || 0) + (+row.delivery_calc_total || 0) + (+row.prepend_total || 0));
    },
    cost_price_unit({row}) {
        if (row.amount_sales) {
            row.cost_price_unit = Math.round(row.cost_price / row.amount_sales)
        }
    },
    cost_price_percent({row}) {
        if (row.sales_plan) {
            row.cost_price_percent = Math.round(row.cost_price / row.sales_plan * 100)
        }
    },

    volume({row}) {
        row.volume = row.length * row.width * row.height / 1000
    },
    wb_commission_unit({row}) {
        row.wb_commission_unit = Math.round((row.new_price || +row.discounted_price * row[resolveTypDelivery[row.variant_delivery]] || 0) / 100);
    },
    wb_commission_percent({row}) {
        row.wb_commission_percent = row[resolveTypDelivery[row.variant_delivery]];
    },

    logistics_tariff({row}) {
        if (row.volume <= 1) {
            row.logistics_tariff = (+row.box_delivery_base || 0);
        } else {
            row.logistics_tariff = (+row.box_delivery_base || 0) + (row.volume - 1) * (+row.box_delivery_liter || 0)
        }
    },
    logistics_with_ransom({row}) {
        row.logistics_with_ransom = row.logistics_tariff + row.logistics_tariff / 100 * (100 - row.ransom_percent)
    },
    storage_over_turnover({row}) {
        if (row.volume <= 1) {
            row.storage_over_turnover = (+row.box_storage_base || 0) * row.product_turnover;
        } else {
            row.storage_over_turnover = ((+row.box_storage_base || 0) + (row.volume - 1) * (row.box_storage_liter || 0)) * row.product_turnover
        }
    },
    storage_per_day({row}) {
        row.storage_per_day = row.box_storage_base
    },

    expenses_mp_unit({row}) {
        row.expenses_mp_unit = (row.wb_commission_unit || 0) + (row.logistics_with_ransom || 0) + (row.storage_over_turnover || 0);
    },
    expenses_mp_sum({row}) {
        row.expenses_mp_sum = (row.expenses_mp_unit || 0) * row.amount_sales;
    },
    expenses_mp_percent({row}) {
        if (row.new_price || +row.discounted_price) {
            row.expenses_mp_percent = Math.round((row.wb_commission_unit || 0) / (row.new_price || +row.discounted_price) * 100);
        }
    },

    promo_total({row}) {
        if (row.promo_percent) {
            row.promo_total = Math.round(row.sales_plan / 100 * row.promo_percent);
        }
    },
    promo_unit({row}) {
        if (row.promo_percent && row.amount_sales) {
            row.promo_unit = Math.round((row.sales_plan / 100 * row.promo_percent) / row.amount_sales);
        }
    },

    receipt_unit({row}) {
        row.receipt_unit = (row.new_price || +row.discounted_price) - ((+row.expenses_mp_unit || 0) + (+row.promo_unit || 0) + (+row.cost_price_unit || 0));
    },
    receipt_part({row}) {
        row.receipt_part = (row.receipt_unit || 0) * row.amount_sales;
    },
    receipt_percent({row}) {
        if (row.new_price || +row.discounted_price) {
            row.receipt_percent = Math.round((row.receipt_unit || 0) / (row.new_price || +row.discounted_price) * 100);
        }

    },

    dues({row}) {
        row.dues = Math.round(row.receipt_part / 100 * (row.dues_percent || 6));
    },
    dues_unit({row}) {
        if (row.amount_sales) {
            row.dues_unit = Math.round((row.dues || 0) / row.amount_sales);
        }
    },

    val_value_unit({row}) {
        row.val_value_unit = Math.round(row.receipt_unit - row.dues_unit);
    },
    val_value_part({row}) {
        row.val_value_part = Math.round(row.val_value_unit * row.amount_sales);
    },
    val_value_percent({row}) {
        if (row.new_price || +row.discounted_price) {
            row.val_value_percent = Math.round((row.val_value_unit || 0) / (row.new_price || +row.discounted_price) * 100);
        }
    },
}

function totalCalc({row, val, fieldName}) {
    if (assignValue[fieldName]) assignValue[fieldName]({row, val});
    for (const fieldNameCalcItems in calcItems) {
        if (fieldNameCalcItems !== fieldName && calcItems[fieldNameCalcItems]) {
            calcItems[fieldNameCalcItems]({row});
        }
    }
}

module.exports = {totalCalc, calcItems, assignValue}
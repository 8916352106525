<template>
  <div class="mt-2">
    <b-table id="table_unit-calculator"
             :fields="getFieldsCalculator"
             :items="productCalculatesList"
             small
             responsive
             ref="table">
      <template #thead-top="data">
        <b-tr>
          <b-th v-for="(item, i) in getMainHeaders"
                :class="`main_${item.name}`"
                :key="item.name+i"
                style="height: 44px"
                variant="primary"
                :colspan="item.childNum">
            <div class="d-flex align-items-center" style="width: 100%; height: 100%"
                 :style="{height: '53px'}"
                 :class="item.hasOwnProperty('group') ? 'justify-content-between' : 'justify-content-center'">
              <div v-if="item.hasOwnProperty('group')" style="width: 19px; height: 14px;"></div>
              <div style="text-transform: none; text-align: center">
                {{ item.mainHeaderName }}
              </div>
              <div v-if="item.hasOwnProperty('group')">
                <feather-icon
                    :icon="item.group ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                    class="cursor-pointer"
                    style="margin-left: 5px"
                    @click="function() {setVisibilityFields( {parentField: item.key})}"
                />
              </div>
            </div>
          </b-th>
        </b-tr>
      </template>
      <template #cell(product)="data">
        <div :style="{width: data.field.columnWidth, paddingBottom: '5px'}">
          <product-cell-unit
              :productData="data.item"
              :details="false"/>
        </div>
      </template>
      <template #cell()="data">
        <div class="cell-input-container d-flex align-items-center justify-content-center">
          <b-form-input
              v-if="data.field.typeCell === 'input'"
              :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
              class="cell-input"
              type="number"
              :value="data.value"
              @input="function(val){inputHandling({row: data.item, val, fieldName: data.field.key})}"
          />
          <div v-else :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''">
            <money-format :cost="data.item[data.field.key]" :def-val="0"/>
          </div>
        </div>
      </template>
      <template #cell(seller_price)="data">
        <div class="d-flex justify-content-center align-items-center">
          <div class="final_price-container d-flex align-items-center">
            <div class="summa-value_container d-flex justify-content-between align-items-center">
              <money-format :cost="Math.round(data.item.discounted_price)" :def-val="0"/>
              <b-form-input class="mx-2 cell-input"
                            type="number"
                            @input="function(val){inputHandling({row: data.item, val, fieldName: 'new_price'})}"/>
            </div>
            <div style="color: #d87a68; font-size: 12px" class="text-left">
              <money-format
                  :cost="Math.round(data.item.discounted_price - data.item.new_price)"
              />
              <div class="d-flex">
                <div>
                  {{ +data.item.new_price > 0 ? Math.round(100 - (+data.item.new_price / data.item.discounted_price * 100)) : 0 }}
                </div>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell(sales_plan)="data">
        <div class="fill-cell-input-container d-flex align-items-center justify-content-center">
          <b-form-input
              v-if="data.item.editNow"
              autofocus
              :ref="data.index + '_sales_plan'"
              :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
              class="fill-cell-input"
              type="number"
              :value="data.value"
              @blur="data.item.editNow = false"
              @input="function(val){inputHandling({row: data.item, val, fieldName: 'sales_plan'})}"/>
          <div v-else
               :style="data.field.columnWidth ? {width: data.field.columnWidth} : ''"
               class="edit-text-result d-flex align-items-center justify-content-center"
               @click="function() {data.item.editNow = true;}">
            <money-format :cost="data.value" :def-val="0"/>
          </div>
        </div>
      </template>
      <template #cell(discount_spp)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-form-input class="cell-input"
                        :style="{width: '28px', marginRight: '4px'}"
                        type="number"
                        :value="data.value"
                        @input="function(val){inputHandling({row: data.item, val, fieldName: 'discount_spp'})}"
          />
          %
        </div>
      </template>
      <template #cell(variant_delivery)="data">
        <b-dropdown id="variants-delivery"
                    variant="primary"
                    size="sm"
                    :text="data.value">
          <template #button-content>
            {{ data.item.variant_delivery }}
          </template>
          <b-dropdown-item
              v-for="variant of variantsDelivery"
              :key="variant.name"
              @click="inputHandling({row: data.item, val: variant.name, fieldName: 'variant_delivery'})"
          >
            <b-badge :variant="variant.color">
              {{ variant.label }}
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(warehouse)="data">
        <b-dropdown id="variants-delivery"
                    variant="primary"
                    size="sm"
                    :text="data.value">
          <div style="max-height: 300px; overflow: auto">
            <b-dropdown-item
                v-for="war of warehousesTariff"
                :key="war.warehouse_name"
                @click="function(){inputHandling({row: data.item, val: war, fieldName: 'warehouse'})}"
            >
              {{ war.warehouse_name }}
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import ProductCellUnit from "@/components/ProductCellUnit";
import CalculateCell from "@/components/UnitCalculatar/CalculateCell";
import CellEdit from "@/components/CellEdit.vue";
import {totalCalc, calcItems} from "@/libs/calcFormulas";

import {
  BTable,
  BFormInput,
  BFormTextarea,
  BFormSelectOption,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BBadge,
  BTr,
  BTh
} from 'bootstrap-vue'

export default {
  name: "UnitCalculator",
  data() {
    return {
      currentVariantDelivery: 'fbo',
      variantsDelivery: [
        {
          name: 'fbo',
          label: 'FBO',
          color: 'light-secondary'
        },
        {
          name: 'fbs',
          label: 'FBS',
          color: 'light-success'
        },
        {
          name: 'dbs',
          label: 'DBS',
          color: 'light-danger'
        },
        {
          name: 'express',
          label: 'EXPRESS',
          color: 'light-danger'
        },
      ],
      calcFormulas: {},
      dues: null
    }
  },

  components: {
    ProductCellUnit,
    CalculateCell,
    BTable,
    BBadge,
    BFormSelectOption,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CellEdit,
    BFormInput,
    BFormTextarea,
    BTr,
    BTh
  },
  computed: {
    ...mapState('unitReport', ["unitReportRows", "unitReportSum"]),
    ...mapState('unitCalculator', ["warehousesTariff", "productCalculatesList"]),
    ...mapGetters('unitCalculator', ["getFieldsCalculator", "getMainHeaders"]),
  },

  methods: {
    ...mapActions("unitCalculator", ["createCalculate", "loadCalculates", "loadTariffBox", "setVisibilityFields"]),

    async inputHandling({row, fieldName, val}) {
      totalCalc({row, val, fieldName});
      await this.createCalculate(row)
    },
  },

  watch: {},

  async mounted() {
    await this.loadCalculates()
    await this.loadTariffBox();
    for (const product of this.productCalculatesList) {
      totalCalc({row: product});
    }
  }
};
</script>

<style>

.fill-cell-input-container {
  width: 90px;
  height: 85px;
}

.fill-cell-input:focus:valid,
.fill-cell-input:focus,
.fill-cell-input:active,
.fill-cell-input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #F9FBFF;
  box-shadow: none;
}

.edit-text-result {
  width: 100%;
  height: 100%;
  text-decoration: underline;
  text-decoration-color: #9ab4e4;
  cursor: pointer;
}

#table_unit-calculator td {
  padding: 0 5px;
  text-align: center;
}

#table_unit-calculator th {
  text-transform: none;
  background-color: white;
  border: none;
  text-align: center;
  border-top: 1px solid #ebe9f1;
}

#table_unit-calculator th:first-child {
  position: sticky;
  left: 0;
  width: 250px;
  background-color: #f8f8f8;
}

.cell-input {
  padding: 0 4px;
  width: 60px;
  height: 30px;
}

#table_unit-calculator thead tr:nth-child(1) th {
  background-color: #A2C0F0;
}

#table_unit-calculator thead tr:nth-child(2) th {
  background-color: #DFEAFB;
}

#table_unit-calculator td.b-table-sticky-column {
  background-color: #eff5ff !important;
}

.cell-input-container {
  margin: 0 5px;
}

.main_promo_summa,
.main_promo_summa_balance,
.main_promo {
  background-color: #ecc3bb !important;
}

.sub_header_promo,
.sub_header_promo_summa,
.sub_header_promo_summa_balance {
  background-color: #f5eae9 !important;
}

.main_receipt,
.main_val_value,
.main_final_price,
.main_discount_spp,
.main_seller_price,
.main_first_price,
.main_amount_sales {
  background-color: #88d2ae !important;
}

.sub_header_amount_sales,
.sub_header_receipt,
.sub_header_val_value,
.sub_header_final_price,
.sub_header_discount_spp,
.sub_header_seller_price,
.sub_header_first_price {
  background-color: #e0f8ec !important;
}

.calc_price_cell {
  background-color: #f8fffc !important;
}

.main_expenses_mp,
.main_variant_delivery,
.main_commission,
.main_wb_commission_percent,
.main_warehouse,
.main_ransom_percent,
.main_logistics_tariff,
.main_logistics_with_ransom,
.main_storage_per_day,
.main_storage_over_turnover,
.main_product_turnover,
.main_volume,
.main_weight,
.main_length,
.main_width,
.main_height {
  background-color: #B5A6D4 !important;
}

.sub_header_expenses_mp,
.sub_header_variant_delivery,
.sub_header_commission,
.sub_header_wb_commission_percent,
.sub_header_warehouse,
.sub_header_ransom_percent,
.sub_header_logistics_tariff,
.sub_header_logistics_with_ransom,
.sub_header_storage_per_day,
.sub_header_storage_over_turnover,
.sub_header_product_turnover,
.sub_header_volume,
.sub_header_weight,
.sub_header_length,
.sub_header_width,
.sub_header_height {
  background-color: #e4dff0 !important;
}

.white_blue {
  background-color: #F9FBFF !important;
}
</style>



<template>
  <div class="mt-2">
    <b-table-simple>

    </b-table-simple>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BTfoot
} from 'bootstrap-vue';

import RowCalculator from "@/components/UnitCalculatar/RowCalculator.vue";
import calculationFormulas from "@/libs/calculationFormulas";

export default {
  name: "TableCalculator",
  components: {
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BTfoot,
    RowCalculator,
  },

  data() {
    return {
      productCalculatesList: [],
    }
  },
  methods: {
    ...mapActions("unitCalculator", ["loadCalculates", "updateListProductCalculate"]),
  },
  async beforeMount() {
    this.calcFormulas = calculationFormulas
    this.productCalculatesList = await this.loadCalculates()
    await this.updateListProductCalculate();
  }
}


</script>

<style scoped>

</style>
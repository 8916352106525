import axios from "axios";

const unitCalculator = {
    namespaced: true,

    state: {
        withColumnValue: {
            product: 280,
            sales_plan: 80,
            wb_commission_unit: 100,
            final_price: 80,
            discount_spp: 60,
            purchase_price_unit: 45,
            delivery_calc_unit: 45,
            prepend_unit: 45,
            product_turnover: 45,
            promo_percent: 28,
        },

        cellTypes: {
            input: [
                'sales_plan',
                'purchase_price_total',
                'purchase_price_unit',
                'delivery_calc_total',
                'delivery_calc_unit',
                'prepend_total',
                'prepend_unit',
                'promo_percent',
                'product_turnover',
                'first_price',
            ]
        },

        productCalculatesList: [],

        fields: [
            {
                key: 'product',
                label: '',
                stickyColumn: true,
                tdClass: 'white_blue'
            },
            {
                key: 'sales_plan',
                label: '',
                tdClass: 'white_blue',
            },

            {
                key: 'final_price',
                label: '',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'discount_spp',
                label: '',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'seller_price',
                label: '',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'first_price',
                label: '',
                tdClass: 'calc_price_cell'
            },

            {
                key: 'amount_sales',
                label: 'шт',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'cost_price',
                label: 'итого р.',
                tdClass: 'white_blue'
            },
            {
                key: 'cost_price_unit',
                label: 'за 1 шт.р.',
                tdClass: 'white_blue'
            },
            {
                key: 'cost_price_percent',
                label: '%',
                tdClass: 'white_blue'
            },

            {
                key: 'purchase_price_total',
                label: 'итого р.',
                tdClass: 'white_blue',
            },
            {
                key: 'purchase_price_unit',
                label: 'за 1 шт.р.',
                tdClass: 'white_blue'
            },
            {
                key: 'delivery_calc_total',
                label: 'итого р.',
                tdClass: 'white_blue'
            },
            {
                key: 'delivery_calc_unit',
                label: 'за 1 шт.р.',
                tdClass: 'white_blue'
            },
            {
                key: 'prepend_total',
                label: 'итого р.',
                tdClass: 'white_blue'
            },
            {
                key: 'prepend_unit',
                label: 'за 1 шт.р.',
                tdClass: 'white_blue'
            },

            {
                key: 'expenses_mp_sum',
                label: 'руб',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'expenses_mp_unit',
                label: 'руб/шт',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'expenses_mp_percent',
                label: '%',
                tdClass: 'td-purpur-white'
            },

            {
                key: 'variant_delivery',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'wb_commission_percent',
                label: '%',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'wb_commission_unit',
                label: 'за 1 шт. р.',
                tdClass: 'td-purpur-white'
            },

            {
                key: 'warehouse',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'ransom_percent',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'logistics_tariff',
                label: 'р.',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'logistics_with_ransom',
                label: 'р.',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'storage_per_day',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'storage_over_turnover',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'product_turnover',
                label: '',
                tdClass: 'td-purpur-white'
            },

            {
                key: 'volume',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'weight',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'length',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'width',
                label: '',
                tdClass: 'td-purpur-white'
            },
            {
                key: 'height',
                label: '',
                tdClass: 'td-purpur-white'
            },

            {
                key: 'promo_total',
                label: 'итого р.',
                tdClass: 'td-ping-white'
            },
            {
                key: 'promo_unit',
                label: 'за 1 шт.р.',
                tdClass: 'td-ping-white'
            },
            {
                key: 'promo_percent',
                label: '%',
                tdClass: 'td-ping-white'
            },

            {
                key: 'receipt_part',
                label: 'итого р.',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'receipt_unit',
                label: 'за 1 шт.р.',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'receipt_percent',
                label: '%',
                tdClass: 'calc_price_cell'
            },

            {
                key: 'dues',
                label: 'руб',
                tdClass: 'white_blue'
            },
            {
                key: 'dues_unit',
                label: 'за 1 шт.р.',
                tdClass: 'white_blue'
            },

            {
                key: 'val_value_part',
                label: 'итого р.',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'val_value_unit',
                label: 'за 1 шт.р.',
                tdClass: 'calc_price_cell'
            },
            {
                key: 'val_value_percent',
                label: '%',
                tdClass: 'calc_price_cell'
            },
        ],

        warehousesTariff: [],

        selfRansomFilter: null,

        relativityGroup: {
            expenses_mp_sum: [
                'variant_delivery',
                'wb_commission_unit',
                'wb_commission_percent',
                'warehouse',
                'ransom_percent',
                'logistics_tariff',
                'logistics_with_ransom',
                'storage_per_day',
                'storage_over_turnover',
                'product_turnover',
                'volume',
                'weight',
                'length',
                'width',
                'height',
            ],
            cost_price: [
                'purchase_price_total',
                'purchase_price_unit',
                'delivery_calc_total',
                'delivery_calc_unit',
                'prepend_total',
                'prepend_unit',
            ],
            final_price: [
                'discount_spp',
                'seller_price',
                'first_price',
            ],
        },

        mainHeaderRelativity: [
            {
                name: 'warehouse',
                label: 'Склад',
                children: [
                    'warehouse',
                ]
            },
            {
                name: 'ransom_percent',
                label: '% Выкупа',
                children: [
                    'ransom_percent',
                ]
            },
            {
                name: 'logistics_tariff',
                label: 'Логистика тариф',
                children: [
                    'logistics_tariff',
                ]
            },
            {
                name: 'logistics_with_ransom',
                label: 'Логистика с учетом % выкупа',
                children: [
                    'logistics_with_ransom',
                ]
            },
            {
                name: 'storage_per_day',
                label: 'Хранение за 1 день',
                children: [
                    'storage_per_day',
                ]
            },
            {
                name: 'storage_over_turnover',
                label: 'Хранение с Оборач.',
                children: [
                    'storage_over_turnover',
                ]
            },
            {
                name: 'product_turnover',
                label: 'Оборачиваемость товара',
                children: [
                    'product_turnover',
                ]
            },
            {
                name: 'volume',
                label: 'Объем, л',
                children: [
                    'volume',
                ]
            },
            {
                name: 'weight',
                label: 'Вес, гр.',
                children: [
                    'weight',
                ]
            },
            {
                name: 'length',
                label: 'Д',
                children: [
                    'length',
                ]
            },
            {
                name: 'width',
                label: 'Ш',
                children: [
                    'width',
                ]
            },
            {
                name: 'height',
                label: 'В',
                children: [
                    'height',
                ]
            },
            {
                name: 'commission',
                label: 'Комиссия МП',
                children: [
                    'wb_commission_unit',
                    'wb_commission_percent',
                ]
            },
            {
                name: 'amount_sales',
                label: 'Кол-во продаж',
                children: [
                    'amount_sales',
                ]
            },
            {
                name: 'variant_delivery',
                label: 'Система поставок',
                children: [
                    'variant_delivery',
                ]
            },
            {
                name: 'expenses_mp',
                label: 'Расходы МП',
                children: [
                    'expenses_mp_sum',
                    'expenses_mp_unit',
                    'expenses_mp_percent',
                ]
            },
            {
                name: 'purchase_price',
                label: 'Цена закупки',
                children: [
                    'purchase_price_total',
                    'purchase_price_unit',
                ]
            },
            {
                name: 'prepend',
                label: 'Подготовка',
                children: [
                    'prepend_total',
                    'prepend_unit',
                ]
            },
            {
                name: 'delivery_calc',
                label: 'Доставка',
                children: [
                    'delivery_calc_total',
                    'delivery_calc_unit',
                ]
            },
            {
                name: 'dues',
                label: 'Налог',
                children: [
                    'dues',
                    'dues_unit'
                ]
            },
            {
                name: 'product',
                label: 'Продукт',
                children: [
                    'product',
                ]
            },
            {
                name: 'sales_plan',
                label: 'План продаж',
                children: [
                    'sales_plan',
                ]
            },
            {
                name: 'final_price',
                label: 'Цена для покупателя',
                children: [
                    'final_price',
                ]
            },
            {
                name: 'discount_spp',
                label: 'Скидка СПП',
                children: [
                    'discount_spp',
                ]
            },
            {
                name: 'seller_price',
                label: 'Цена продавца',
                children: [
                    'seller_price',
                ]
            },
            {
                name: 'first_price',
                label: 'Цена до скидки',
                children: [
                    'first_price',
                ]
            },
            {
                name: 'promo',
                label: 'Продвиж.',
                children: [
                    'promo_total',
                    'promo_unit',
                    'promo_percent',
                ]
            },
            {
                name: 'cost_price',
                label: 'Себес',
                children: [
                    'cost_price',
                    'cost_price_unit',
                    'cost_price_percent',
                ]
            },
            {
                name: 'receipt',
                label: 'Поступление на РС',
                children: [
                    'receipt_part',
                    'receipt_unit',
                    'receipt_percent',
                ]
            },
            {
                name: 'val_value',
                label: 'Валовая прибыль',
                children: [
                    'val_value_part',
                    'val_value_unit',
                    'val_value_percent',
                ]
            },
        ],
    },

    //paidStorageKgvp     - fbo
    //kgvpMarketplace     - fbs
    //kgvpSupplier        - dbs
    //kgvpSupplierExpress - express

    getters: {
        getFieldsCalculator(state, getters) {
            return state.fields.filter(field => field.visibility === true)
        },

        getFieldsByGroup(state) {
            return state.fields.filter(field => field.visibility === true)
        },

        getMainHeaders(state, getters) {
            let mainHeaders = []
            let mainHeadersReg = {}
            for (const unitField of getters.getFieldsByGroup) {
                if (!mainHeadersReg[unitField.mainHeaderName || unitField.key]) {
                    mainHeadersReg[unitField.mainHeaderName || unitField.key] = {
                        [unitField.mainHeaderName || unitField.key]: 1
                    };
                    let mainHeader = {
                        name: unitField.mainHeader || '',
                        childNum: 1,
                        mainHeaderName: unitField.mainHeaderName || '',
                        visibility: unitField.visibility,
                        label: unitField.label,
                        key: unitField.key,
                        parentVisibility: unitField.parentVisibility,
                    }
                    if (unitField.hasOwnProperty('group')) mainHeader.group = unitField.group;
                    mainHeaders.push(mainHeader);
                } else {
                    let hr = mainHeaders.find(item => item.mainHeaderName === unitField.mainHeaderName);
                    hr.childNum++
                }
            }
            return mainHeaders
        }
    },

    mutations: {
        SET_PRODUCT_CALCULATE_LIST(state, calculates) {
            state.productCalculatesList = calculates;
        },

        UPDATE_LIST_PRODUCT_CALCULATE_BOX(state, tariffs) {
            state.warehousesTariff = tariffs
        },
    },

    actions: {
        setVisibilityFields({state}, {parentField}) {
            for (const field of state.fields) {
                if (field.parentVisibility === parentField) field.visibility = !field.visibility;
                if (field.key === parentField) field.group = !field.group;
            }
        },

        async sendCurrentShipment({commit, state, dispatch, rootState}, currentShipment) {

        },

        async loadCalculates({commit, dispatch, rootState}) {
            try {
                let calculates = await axios.get('/statistics/calculate/get_calculates');
                if (calculates.data) {
                    commit("SET_PRODUCT_CALCULATE_LIST", calculates.data);
                }
            } catch (e) {
                console.log(e)
            }
        },

        async initListProductCalculate({state, dispatch, rootState}) {
            await dispatch('loadCalculates');
            return rootState["unitReport"].unitReportRows.map(row => ({
                is_dimensional: row.is_dimensional,
                _showDetails: row._showDetails,
                imgMain: row.imgMain,
                subjectName: row.subjectName,
                title: row.title,
                nmID: row.nmID,
                price: row.price,
                discounted_price: row.discounted_price,
                vendorCode: row.vendorCode,
                valuation: row.valuation,
                feedbacksCount: row.feedbacksCount,
                sales_plan: '',
                final_price: '',
                amount_sales: '',
                priceByApi: '',
                discount_spp: Math.round(row.spp),
                seller_price: Math.round(row.discounted_price),
                first_price: '',
                purchase_price_total: '',
                purchase_price_unit: '',
                delivery_calc_total: '',
                delivery_calc_unit: '',
                prepend_total: '',
                prepend_unit: '',
                cost_price: '',
                cost_price_unit: '',
                cost_price_percent: '',
                dues: '',
                promo_total: '',
                promo_unit: '',
                promo_percent: '',
                variant_delivery: 'fbo',
                kgvp_marketplace: 0,
                kgvp_supplier: 0,
                kgvp_supplier_express: 0,
                paid_storage_kgvp: 0,
                wb_commission_percent: '',
                wb_commission_unit: '',
                warehouse: '',
                receipt_part: '',
                receipt_unit: '',
                receipt_percent: '',
                val_value_part: '',
                val_value_unit: '',
                val_value_percent: '',
                box_delivery_and_storage_expr: '',
                box_delivery_base: '',
                box_delivery_liter: '',
                box_storage_base: '',
                parent_id: '',
                product_turnover: '',
                dues_unit: '',
            }))
        },

        async loadTariffBox({commit}) {
            /*try {
                let tariffs = await axios.get('/statistics/warehouses/get_tariff');
                if (tariffs.data) {
                    commit("UPDATE_LIST_PRODUCT_CALCULATE", tariffs.data);
                }
            } catch (e) {
                console.log(e)
            }*/
            try {
                let tariffsBox = await axios.get('/statistics/warehouses/get_tariff_box');
                if (tariffsBox.data) {
                    commit("UPDATE_LIST_PRODUCT_CALCULATE_BOX", tariffsBox.data);
                }
            } catch (e) {
                console.log(e)
            }
        },

        async createCalculate({state, dispatch, rootState}, rowCalc) {
            try {
                await axios.post('/statistics/calculate/create_calculate', {rowCalc});
            } catch (e) {
                console.log(e)
            }
        },

        _prepare() {
            for (const unitField of unitCalculator.state.fields) {
                if (!unitField.hasOwnProperty('visibility')) unitField.visibility = true;

                if (unitCalculator.state.withColumnValue[unitField.key]) unitField.columnWidth = unitCalculator.state.withColumnValue[unitField.key] + 'px'

                unitCalculator.actions._updateFieldsMainHeaders(unitField);
                unitCalculator.actions._addGroup(unitField);
                unitCalculator.actions._addTypeCell(unitField);
            }
        },

        _addTypeCell(unitField) {
            for (const nameType in unitCalculator.state.cellTypes) {
                const list = unitCalculator.state.cellTypes[nameType]
                for (const key of list) {
                    if (unitField.key === key) {
                        unitField.typeCell = nameType;
                        return
                    }
                }
            }
        },

        _updateFieldsMainHeaders(unitField) {
            for (const headerItem of unitCalculator.state.mainHeaderRelativity) {
                for (const fieldName of headerItem.children) {
                    if (unitField.key === fieldName) {
                        if (!unitField.thClass) unitField.thClass = [`sub_header_${headerItem.name}`];
                        else if (unitField.thClass.indexOf(`sub_header_${headerItem.name}`) === -1) unitField.thClass.push(`sub_header_${headerItem.name}`);
                        unitField.mainHeader = headerItem.name
                        unitField.mainHeaderName = headerItem.label
                        return
                    }
                }
            }
            unitField.mainHeader = false
        },

        _addGroup(unitField) {
            for (const fieldName in unitCalculator.state.relativityGroup) {
                let childFields = unitCalculator.state.relativityGroup[fieldName];
                if (!Array.isArray(childFields)) continue;
                for (const childFieldName of childFields) {
                    if (unitField.key === childFieldName) {
                        unitField.visibility = false;
                        unitField.parentVisibility = fieldName;
                        return
                    } else if (unitField.key === fieldName) {
                        unitField.group = false;
                    }
                }
            }
        }
    },
};

unitCalculator.actions._prepare()

export default unitCalculator
<template>
  <b-card>
    <div class="calculator-container">
      <div class="control-panel">

      </div>
      <div class="calculator-container">
        <unit-calculator/>
      </div>
    </div>
  </b-card>
</template>
<script>

import {BCard} from 'bootstrap-vue';
import TableCalculator from "@/components/UnitCalculatar/TableCalculator.vue";
import UnitCalculator from "@/components/unitReport/UnitCalculator.vue";

export default {
  components: {
    UnitCalculator,
    BCard,
    TableCalculator
  },
  name: "Calculator"
}
</script>

<style scoped>

</style>
<template>
  <b-tr>

  </b-tr>
</template>

<script>
import {
  BTr,
  BTd,
} from 'bootstrap-vue';
import RowCalculator from "@/components/UnitCalculatar/RowCalculator.vue";

export default {
  name: "RowCalculator",
  components: {
    BTr,
    BTd,
  }
}
</script>

<style scoped>

</style>